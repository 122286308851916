var types = [
    {
        name: "tracks.types.driving",
        icon: "fa-car",
        value: 1
    },
    {
        name: "tracks.types.walking",
        icon: "fa-walking",
        value: 2
    },
    {
        name: "tracks.types.running",
        icon: "fa-running",
        value: 7
    },
    {
        name: "tracks.types.hiking",
        icon: "fa-hiking",
        value: 3
    },
    {
        name: "tracks.types.biking",
        icon: "fa-biking",
        value: 4
    },
    {
        name: "tracks.types.sailing",
        icon: "fa-ship",
        value: 5
    },
    {
        name: "tracks.types.boating",
        icon: "fa-ship",
        value: 6
    },
    {
        name: "tracks.types.sledding",
        icon: "fa-sledding",
        value: 8
    },
    {
        name: "tracks.types.skiing",
        icon: "fa-skiing",
        value: 9
    },
    {
        name: "tracks.types.snowboarding",
        icon: "fa-snowboarding",
        value: 10
    }
];

var getTrackType = function (type_number) {
    var result;
    types.forEach(function(type) {
        if (type.value === type_number) {
            result = type;
        }
    });
    return result;
}

export default { types, getTrackType };